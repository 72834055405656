import { ISection } from '@/shared/models/Section';
import { IWebsitePage } from '@/shared/models/Website';
import { createContext, useContext } from 'react'


export interface IPageContextState  {
  page: IWebsitePage,
  sections: ISection[]
}


const PageContextState: IPageContextState = {
  page: null,
  sections: []
};
  
export const PageContext = createContext<IPageContextState>(PageContextState);

export function usePage(): IPageContextState {
  return useContext(PageContext)
}

export default PageContext



