import { useRouter } from 'next/router';

import { WebsitePageType } from '@/shared/models/Website';
import { useClub } from 'contexts/club';
import { buildUrl } from './clubs';
import { isEmpty, isUndefined } from '@/shared/utils/lodashFunc';


export function useSystemPagePath(type: WebsitePageType, urlParameters?: { [key: string]: number}): string {
    const { getSystemPageSeo } = useClub()
    const router = useRouter()
    const pageSeo = getSystemPageSeo(type)
    const query = router.query

    let path = buildUrl(query.prefix as string, pageSeo ? `/${pageSeo}` : '')
    if (!isUndefined(urlParameters) && !isEmpty(urlParameters)) {
        Object.keys(urlParameters).forEach(key => {
            if (!isEmpty(urlParameters[key])) {
                path = path + `/${key}/${urlParameters[key]}`
            }
        })
    }

    return path
}