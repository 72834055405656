import { ISection, ISectionOrigin, SectionType } from '@/shared/models/Section'
import { chunk } from '@/shared/utils/lodashFunc'
import { INews } from '@/shared/models/News'
import { ISeason } from '@/shared/models/Season'
import { ICategory } from '@/shared/models/Category'
import { IMessages } from '@/shared/models/Messages'
import { ICountry } from '@/shared/models/Country'
import { IPost } from '@/shared/models/Post'
import { useSystemPagePath } from 'utils/hooks'
import { WebsitePageType } from '@/shared/models/Website'
import { ISponsorsSectionData } from './Sponsors/types'
import { INewsSectionData } from './News/types'
import { IGallerySectionData } from './Galleries/types'
import { IVideosSectionData } from './Videos/types'
import { ICalendarSectionData } from './Calendar/types'
import { IPlayerStatsSectionData } from './PlayerStats/types'
import { IStandingsSectionData } from './Standings/types'
import { IFormsSectionData } from './Forms/types'
import { IShopSectionData } from './Shop/types'
import { IGamesSectionData } from './Games/types'
import { IRosterSectionData } from './Roster/types'
import { IPlayerSectionData } from './Player/types'
import { NEWS_ARTICLE_KEY, PRESS_ARTICLE_KEY } from './News/const'
import { ISingleGallerySectionData } from './SingleGallery/types'
import { IEventSectionData } from './Event/types'
import { IGameSectionData } from './Game/types'
import { IPostsSectionData } from './Post/types'
import { POST_ARTICLE_KEY } from './Post/consts'
import { IGroupSectionData} from './Group/types'

export type ISectionData = ISponsorsSectionData &
    INewsSectionData &
    IGallerySectionData &
    IVideosSectionData &
    ICalendarSectionData &
    IPlayerStatsSectionData &
    IStandingsSectionData &
    IFormsSectionData &
    IShopSectionData &
    IGamesSectionData &
    IRosterSectionData &
    IPlayerSectionData &
    ISingleGallerySectionData &
    IEventSectionData &
    IGameSectionData &
    IPostsSectionData &
    IGroupSectionData //NOTE can be different - depends on the section type. Please add your type here like ISponsorSectionData & IYOURSectionData

export interface IGetSectionDataParams {
    prefix: string
    userLanguage: string
    query: { [key: string]: string }
    section: ISection
    token?: string
    isTokenExpired?: boolean
    seasonTypeOne?: ISeason[]
    seasonTypeTwo?: ISeason[]
    categoriesPlaceholders?: {
        categories: ICategory[]
        placeholders: IMessages
    }
    countriesPlaceholders?: {
        countries: ICountry[]
        placeholders: IMessages
    }
}

export interface ISectionComponentProps {
    data: ISectionData
    section: ISection
    isPreview?: boolean
}

export async function getSectionData({
    type,
    params,
}: {
    type: SectionType
    params: IGetSectionDataParams
}): Promise<ISectionData> {
    switch (type) {
        case SectionType.SPONSORS: {
            const getDataFunction = (await import('components/Sections/Sponsors/getServerSideProps')).getData
            return getDataFunction(params)
        }
        case SectionType.NEWS: {
            const getDataFunction = (await import('components/Sections/News/getServerSideProps')).getData
            return getDataFunction(params)
        }
        case SectionType.GALLERIES: {
            const getDataFunction = (await import('components/Sections/Galleries/getServerSideProps')).getData
            return getDataFunction(params)
        }
        case SectionType.VIDEOS: {
            const getDataFunction = (await import('components/Sections/Videos/getServerSideProps')).getData
            return getDataFunction(params)
        }
        case SectionType.VIDEO: {
            const getDataFunction = (await import('components/Sections/Video/getServerSideProps')).getData
            return getDataFunction(params)
        }
        case SectionType.CALENDAR: {
            const getDataFunction = (await import('components/Sections/Calendar/getServerSideProps')).getData
            return getDataFunction(params)
        }
        case SectionType.STATISTICS: {
            const getDataFunction = (await import('components/Sections/PlayerStats/getServerSideProps')).getData
            return getDataFunction(params)
        }
        case SectionType.STANDINGS: {
            const getDataFunction = (await import('components/Sections/Standings/getServerSideProps')).getData
            return getDataFunction(params)
        }
        case SectionType.FORMS: {
            const getDataFunction = (await import('components/Sections/Forms/getServerSideProps')).getData
            return getDataFunction(params)
        }
        case SectionType.RESULTS: {
            const getDataFunction = (await import('components/Sections/Games/getServerSideProps')).getData
            return getDataFunction(params)
        }
        case SectionType.ROSTER: {
            const getDataFunction = (await import('components/Sections/Roster/getServerSideProps')).getData
            return getDataFunction(params)
        }
        case SectionType.PLAYER: {
            const getDataFunction = (await import('components/Sections/Player/getServerSideProps')).getData
            return getDataFunction(params)
        }
        case SectionType.SHOP: {
            const getDataFunction = (await import('components/Sections/Shop/getServerSideProps')).getData
            return getDataFunction(params)
        }
        case SectionType.GALLERY: {
            const getDataFunction = (await import('components/Sections/SingleGallery/getServerSideProps')).getData
            return getDataFunction(params)
        }
        case SectionType.TRAINING: {
            const getDataFunction = (await import('components/Sections/Event/getServerSideProps')).getData
            return getDataFunction(params)
        }
        case SectionType.EVENT: {
            const getDataFunction = (await import('components/Sections/Event/getServerSideProps')).getData
            return getDataFunction(params)
        }
        case SectionType.GAME: {
            const getDataFunction = (await import('components/Sections/Game/getServerSideProps')).getData
            return getDataFunction(params)
        }
        case SectionType.COMBINATION: {
            const getDataFunction = (await import('components/Sections/Combination/getServerSideProps')).getData
            return getDataFunction(params)
        }
        case SectionType.POSTS: {
            const getDataFunction = (await import('components/Sections/Post/getServerSideProps')).getData
            return getDataFunction(params)
        }
        case SectionType.POST: {
            const getDataFunction = (await import('components/Sections/Post/getServerSideProps')).getData
            return getDataFunction(params)
        }
        case SectionType.GROUP: {
            const getDataFunction = (await import('components/Sections/Group/getServerSideProps')).getData
            return getDataFunction(params)
        }
        case SectionType.LINK: // no data fetching, only depends on section props
        default: {
            return Promise.resolve({})
        }
    }
}

export async function fetchSectionData({ sections, params }: { sections: ISection[]; params: IGetSectionDataParams }) {
    const sectionPromises = sections.map((section) => {
        return {
            section,
            getData: getSectionData({
                type: section.system_section.type,
                params: {
                    ...params,
                    section,
                },
            }),
        }
    })
    const sectionData = await Promise.all(sectionPromises.map((promise) => promise.getData))

    const sectionsMapped = sectionPromises.map((promise, index) => ({
        id: promise.section.id,
        section: promise.section,
        data: sectionData[index],
    }))
    return sectionsMapped
}

export function getPageSlugParams(slugs: string | string[]) {
    typeof slugs === 'string' ? (slugs = [slugs]) : slugs
    const slugParams = slugs.slice(1) // remove seopath
    return chunk(slugParams, 2).reduce<{ [key in string]?: string }>((acc, [key, value]) => {
        return {
            ...acc,
            [key]: value,
        }
    }, {})
}

export function buildUrlFromParams(
    base: string,
    params: { [key in string]: string | number },
    keys: string[],
    query?: { [key in string]?: string }
) {
    let url = keys
        .reduce(
            (acc, key) => {
                if (Boolean(params[key])) {
                    acc.push(key, params[key].toString())
                }
                return acc
            },
            [base]
        )
        .join('/')
    if (Object.keys(query ?? {}).length) {
        const qs = new URLSearchParams(query)
        url = url + `?${qs}`
    }
    return url
}

export function getImageBackgroundUrls(fileName: string, clubId: number) {
    const nameSplit = fileName.split('.')
    const ext = nameSplit.pop()
    const fileNameWebp = `${nameSplit.join('.')}.webp`

    return {
        webp: {
            url: `${process.env.NEXT_PUBLIC_IMAGE_CDN_URL}/${clubId}/files/image/${fileNameWebp}`,
            type: 'image/webp',
        },
        default: {
            url: `${process.env.NEXT_PUBLIC_IMAGE_CDN_URL}/${clubId}/files/image/${fileName}`,
            type: `image/${ext}`,
        },
    }
}

export function buildNewsUrl(baseUrl: string, news: INews) {
    const urls = [baseUrl]
    if (news.type === 'custom') {
        urls.push(NEWS_ARTICLE_KEY)
    } else {
        urls.push(PRESS_ARTICLE_KEY)
    }
    urls.push(`${news.id}`)
    return urls.join('/')
}

export function isSystemOrPredefinedSection(section: ISection): boolean {
    return [ISectionOrigin.SYSTEM, ISectionOrigin.PREDEFINED].includes(section.origin)
}

export function getMediaOfPost(post: IPost): string {
    const imagesContent = post?.contents?.find((c) => c.layout === 'images')
    if (imagesContent?.layout === 'images') {
        return imagesContent.content?.images.find(i => i.cover)?.url
    }

    return ''
}

export function getPostUrl(post: IPost): string {
    /* eslint-disable react-hooks/rules-of-hooks */
    const postsBaseLink = useSystemPagePath(WebsitePageType.POST)
    return `${postsBaseLink}/${POST_ARTICLE_KEY}/${post?.id}`
}

export function detectVideoPlatform(url: string): 'youtube' | 'vimeo' {
    const youtubeRegex = /^(https?:\/\/)?(www\.)?(youtube\.com\/(watch\?v=|embed\/)|youtu\.be\/)/
    const vimeoRegex = /^(https?:\/\/)?(www\.)?(vimeo\.com\/)/

    if (youtubeRegex.test(url)) {
        return 'youtube'
    } else if (vimeoRegex.test(url)) {
        return 'vimeo'
    }

    return null
}

export function getYoutubePreview(url: string): string {
    const regExp = /(?:https?:\/\/)?(?:www\.)?(?:youtu\.be\/|watch\?v=)([^#&?]*)/i
    const match = url.match(regExp)
    const result = match && match[1].length === 11 ? match[1] : null
    if (result) return `https://img.youtube.com/vi/${result}/0.jpg`
}

export function getVimeoId(url: string): string {
    const vimeoRegex = /(?:https?:\/\/)?(?:player\.)?(vimeo\.com\/)([a-z0-9]*)/i
    const match = url.match(vimeoRegex)

    if (match) {
        return match[2]
    } else {
        return null
    }
}