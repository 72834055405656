import { ISection, SectionType } from '@/shared/models/Section';
import { IWebsitePage, WebsitePageType } from '@/shared/models/Website';
import { isEmpty } from '@/shared/utils/lodashFunc';

import MainLayout from 'components/Layout/MainLayout';
import { ISectionComponentProps, ISectionData, fetchSectionData } from 'components/Sections/utils';

import { useClub } from 'contexts/club';
import PageContext from 'contexts/page';
import { useSnackbar } from 'contexts/snackbar';
import { GetServerSideProps, InferGetServerSidePropsType } from 'next';
import dynamic from 'next/dynamic';
import React, { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { getWebsitePageSections } from 'services/websites';
import { getServerClubPrefix } from 'utils/clubs';
import { S50_LANG_COOKIE, S50_REFRESH_TOKEN_COOKIE, S50_TOKEN_COOKIE, detectToken } from 'utils/cookies';
import { isDev } from 'utils/helper';
import { parseSeoPath } from 'utils/seoPath';

const SectionWrapper = dynamic(() => import('components/Sections/SectionWrapper/SectionWrapper'), {})
const PageMetaTag = dynamic(() => import('components/Meta/PageMetaTag'), {})
const PageSectionMeta = dynamic(() => import('components/Meta/PageSectionMeta'), {})
const Loader = dynamic(() => import('@/shared/components/Loader/Loader'), {})
const Error = dynamic(() => import('components/Error/Error'), {})

const DynamicSponsorsSectionComponent = dynamic(() => import('components/Sections/Sponsors/SponsorsContainer'), {
  loading: function displayLoader() {
    return <Loader />
  },
})

const DynamicNewsSectionComponent = dynamic(() => import('components/Sections/News/NewsContainer'), {
  loading: function displayLoader() {
    return <Loader />
  },
})

const DynamicGalleriesSectionComponent = dynamic(() => import('components/Sections/Galleries/GalleriesContainer'), {
  loading: function displayLoader() {
    return <Loader />
  },
})

const DynamicVideosSectionComponent = dynamic(() => import('components/Sections/Videos/VideosContainer'), {
  loading: function displayLoader() {
    return <Loader />
  },
})

const DyanamicVideoSectionComponent = dynamic(() => import('components/Sections/Video/VideoContainer'), {
  loading: function displayLoader() {
    return <Loader />
  },
})

const DynamicCalendarSectionComponent = dynamic(() => import('components/Sections/Calendar/CalendarContainer'), {
  loading: function displayLoader() {
    return <Loader />
  },
})

const DynamicTextSectionComponent = dynamic(() => import('components/Sections/Text/TextContainer'), {
  loading: function displayLoader() {
    return <Loader />
  },
})

const DynamicHTMLSectionComponent = dynamic(() => import('components/Sections/HTML/HTMLContainer'), {
  loading: function displayLoader() {
    return <Loader />
  },
})

const DynamicPlayerStatsSectionComponent = dynamic(() => import('components/Sections/PlayerStats/PlayerStatsContainer'), {
  loading: function displayLoader() {
    return <Loader />
  },
})

const DynamicStandingsSectionComponent = dynamic(() => import('components/Sections/Standings/StandingsContainer'), {
  loading: function displayLoader() {
    return <Loader />
  },
})

const DynamicLinkSectionComponent = dynamic(() => import('components/Sections/Link/LinkContainer'), {
  loading: function displayLoader() {
    return <Loader />
  },
})

const DynamicFormsSectionComponent = dynamic(() => import('components/Sections/Forms/FormsContainer'), {
  loading: function displayLoader() {
    return <Loader />
  },
})

const DynamicShopSectionComponent = dynamic(() => import('components/Sections/Shop/ShopContainer'), {
  loading: function displayLoader() {
    return <Loader />
  },
})

const DynamicGamesSectionComponent = dynamic(() => import('components/Sections/Games/GamesContainer'), {
  loading: function displayLoader() {
    return <Loader />
  },
})

const DynamicImageSectionComponent = dynamic(() => import('components/Sections/Image/ImageContainer'), {
  loading: function displayLoader() {
    return <Loader />
  },
})

const DynamicDividerSectionComponent = dynamic(() => import('components/Sections/Divider/DividerContainer'), {
  loading: function displayLoader() {
    return <Loader />
  },
})

const DynamicSocialNetworksSectionComponent = dynamic(
  () => import('components/Sections/SocialNetwork/SocialNetworkContainer'),
  {
    loading: function displayLoader() {
      return <Loader />
    },
  }
)

const DynamicDocumentSectionComponent = dynamic(() => import('components/Sections/Documents/DocumentsContainer'), {
  loading: function displayLoader() {
    return <Loader />
  },
})

const DynamicRosterSectionComponent = dynamic(() => import('components/Sections/Roster/RosterContainer'), {
  loading: function displayLoader() {
    return <Loader />
  },
})

const DynamicPlayerSectionComponent = dynamic(() => import('components/Sections/Player/PlayerContainer'), {
  loading: function displayLoader() {
    return <Loader />
  },
})

const DynamicSingleGallerySectionComponent = dynamic(
  () => import('components/Sections/SingleGallery/SingleGalleryContainer'),
  {
    loading: function displayLoader() {
      return <Loader />
    },
  }
)

const DynamicEventSectionComponent = dynamic(() => import('components/Sections/Event/EventContainer'), {
  loading: function displayLoader() {
    return <Loader />
  },
})

const DynamicGameSectionComponent = dynamic(() => import('components/Sections/Game/GameContainer'), {
  loading: function displayLoader() {
    return <Loader />
  },
})

const DynamicCombinationSectionComponent = dynamic(() => import('components/Sections/Combination/CombinationContainer'), {
  loading: function displayLoader() {
    return <Loader />
  },
})

const DynamicPostSectionComponent = dynamic(() => import('components/Sections/Post/PostContainer'), {
  loading: function displayLoader() {
    return <Loader />
  },
})

const DynamicGroupSectionComponent = dynamic(() => import('components/Sections/Group/GroupContainer'), {
  loading: function displayLoader() {
    return <Loader />
  },
})

export interface IPageProps {
  page: IWebsitePage
  sections: ISection[]
  initialSections?: {
    id: number
    section: ISection
    data: ISectionData
  }[]
  sectionsData?: {
    id: number
    data: ISectionData
  }[]
  error?: {
    message: string
    code: number
    url: string
  }
  params: any
}

export default function Page({
  initialSections,
  sections,
  params,
  error,
}: IPageProps & InferGetServerSidePropsType<typeof getServerSideProps>): React.ReactElement {
  const { club: { id: clubId } = {}, page } = useClub()

  const [allSections, setAllSections] = useState([])
  const [loading, setLoading] = useState(false)
  const { showSnackbar } = useSnackbar()

  const getSectionsData = async () => {
    setAllSections([])
    setLoading(true)
    if (sections && sections?.length > 1) {
      const sectionsMapped = await fetchSectionData({ sections: sections.slice(1), params })
      setAllSections(sectionsMapped)
    }
    setLoading(false)
  }

  useEffect(() => {
    getSectionsData()
  }, [sections])
  
  useEffect(() => {
    if (params?.isTokenExpired) {
      showSnackbar({
        message: <FormattedMessage id='v4_website.website.session_expired' defaultMessage='Your session has expired' />,
        variant: 'warning',
        autoHideDuration: 5000,
      })
    }
  }, [params?.isTokenExpired])

  if (error && error?.code !== 401 && !sections?.length) {
    return <Error statusCode={error.code} title={error.message} />
  }

  function renderSectionComponent({ type, props }: { type: SectionType; props: ISectionComponentProps }) {

    switch (type) {
      case SectionType.SPONSORS: {
        return <DynamicSponsorsSectionComponent {...props} />
      }
      case SectionType.NEWS: {
        return <DynamicNewsSectionComponent {...props} />
      }
      case SectionType.GALLERIES: {
        return <DynamicGalleriesSectionComponent {...props} />
      }
      case SectionType.VIDEO: {
        return <DyanamicVideoSectionComponent {...props} />
      }
      case SectionType.VIDEOS: {
        return <DynamicVideosSectionComponent {...props} />
      }
      case SectionType.CALENDAR: {
        return <DynamicCalendarSectionComponent {...props} />
      }
      case SectionType.TEXT: {
        return <DynamicTextSectionComponent {...props} />
      }
      case SectionType.HTML: {
        return <DynamicHTMLSectionComponent {...props} />
      }
      case SectionType.STATISTICS: {
        return <DynamicPlayerStatsSectionComponent {...props} />
      }
      case SectionType.STANDINGS: {
        return <DynamicStandingsSectionComponent {...props} />
      }
      case SectionType.LINK: {
        return <DynamicLinkSectionComponent {...props} />
      }
      case SectionType.DIVIDER: {
        return <DynamicDividerSectionComponent {...props} />
      }
      case SectionType.FORMS: {
        return <DynamicFormsSectionComponent {...props} />
      }
      case SectionType.SHOP: {
        return <DynamicShopSectionComponent {...props} />
      }
      case SectionType.RESULTS: {
        return <DynamicGamesSectionComponent {...props} />
      }
      case SectionType.IMAGE: {
        return <DynamicImageSectionComponent {...props} />
      }
      case SectionType.SHARE: {
        return <DynamicSocialNetworksSectionComponent {...props} />
      }
      case SectionType.DOCUMENT: {
        return <DynamicDocumentSectionComponent {...props} />
      }
      case SectionType.ROSTER: {
        return <DynamicRosterSectionComponent {...props} />
      }
      case SectionType.PLAYER: {
        return <DynamicPlayerSectionComponent {...props} />
      }
      case SectionType.GALLERY: {
        return <DynamicSingleGallerySectionComponent {...props} />
      }
      case SectionType.TRAINING: {
        return <DynamicEventSectionComponent {...props} />
      }
      case SectionType.EVENT: {
        return <DynamicEventSectionComponent {...props} />
      }
      case SectionType.GAME: {
        return <DynamicGameSectionComponent {...props} />
      }
      case SectionType.COMBINATION: {
        return <DynamicCombinationSectionComponent {...props} />
      }
      case SectionType.POSTS: {
        return <DynamicPostSectionComponent {...props} />
      }
      case SectionType.POST: {
        return <DynamicPostSectionComponent {...props} />
      }
      case SectionType.GROUP: {
        return <DynamicGroupSectionComponent {...props} />
      }
      default: {
        return <div>Section &lsquo;{type}&lsquo; does not have component yet</div>
      }
    }
  }

  const loadSection = (sections) => {
    if (!sections || sections.length < 1) {
      return null
    }

    return (
      <>
        {sections.map((section) => {
          const props: ISectionComponentProps = {
            section: section.section,
            data: section.data || null,
          }

          return (
            <SectionWrapper key={section.id} section={section.section} clubId={clubId}>
              <PageSectionMeta {...props} />
              {renderSectionComponent({ type: section.section.system_section.type, props })}
            </SectionWrapper>
          )
        })}
      </>
    )
  }

  return (
    <MainLayout>
      <PageContext.Provider
        value={{
          page,
          sections,
        }}
      >
        <PageMetaTag />
        {loadSection(initialSections)}
        {loadSection(allSections)}
        {loading ? <Loader /> : null}
      </PageContext.Provider>
    </MainLayout>
  )
}

export const getServerSideProps: GetServerSideProps = async (context) => {
  const extraProps: IPageProps = {
    page: null,
    sections: null,
    initialSections: null,
    error: null,
    params: null,
  }

  const { prefix: queryPrefix, slug = [] } = context.query as { [key: string]: string }
  const { req, query } = context
  const token = detectToken({ req, context }) || ''
  let pageId: number | string = 0
  const prefix = getServerClubPrefix(queryPrefix, req)

  if (!query.slug || query.slug?.[0] === 'web' || query.slug?.length === 0) {
    pageId = WebsitePageType.HOME
  } else {
    const pageSeo = parseSeoPath(query.slug?.[0])
    pageId = pageSeo?.id
  }

  if (isDev) {
    console.log('getServerSideProps in [[...slug]].tsx', queryPrefix, slug)
  }
  try {
    const userLanguage = (context.query[S50_LANG_COOKIE] as string) || 'en'
    const query = context.query as { [key: string]: string }
    const params = { prefix: prefix.api, userLanguage, query, token }
    extraProps.params = params

    const sections = await getWebsitePageSections({ clubPrefix: prefix.api, pageId, token }).catch((error) => {
      // if token is expired, try to fetch sections without token
      if (error?.code === 401) {
        return getWebsitePageSections({ clubPrefix: prefix.api, pageId, token: null })
      }
      throw error
    })
  
    const initialSections = sections ? sections.slice(0, 1) : []
    extraProps.sections = sections || []

    if (!isEmpty(initialSections)) {
      extraProps.initialSections = await fetchSectionData({ sections: initialSections, params: extraProps.params })
    }
  } catch (error) {
    if (isDev) {
      // console.log('prefix: ', prefix, ' | pageId: ', pageId)
      console.error('error in `/src/pages/[prefix]/[[...slug]].tsx`, ', error)
    }

    extraProps.error = {
      code: error.code ? error.code : 0,
      message: error.message ? error.message : '',
      url: error.url ? error.url : '',
    }
  }

  delete context.query?.[S50_TOKEN_COOKIE]
  delete context.query?.[S50_REFRESH_TOKEN_COOKIE]

  return {
    props: extraProps,
  }
}